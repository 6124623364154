<template>

  <div class="app">
    
    <div class="user">
      <img :src="userInfo.avatarUrl" alt="">
      <div>
        <div class="name">
          <h4>{{ userInfo.nickName }}</h4>
          <span>{{"ID："+id }}</span>
        </div>
        <span>分享给你ta的选号</span>
      </div>
    </div>

    <div class="win">
      <img src="./img/win.png" alt="">
      <div class="left">
        <div class="count">
          <span>中奖次数</span>
          <span>{{winCount+'次'}}</span>
        </div>
        <div style="width: 1px;height: 20px;background-color: #f7cdc5;margin: 0 20px;"></div>
        <div class="count">
          <span>总中奖金额</span>
          <span>{{winAmount}}</span>
        </div>
      </div>
      <button class="right" @click="checkClick">查看战绩</button>
    </div>

    <div class="remark">
      <span><img src="./img/tishi.png" alt="" style="display: block; width: 13px; height: 13px;"></span>
      <span>{{ remark }}</span>
    </div>
    <div v-for="(item, index) in lotteryList" :key="index" class="items">
      <div class="item">
        <div>
          <div>{{ item.name }} {{ item.issue }}</div>
          <div>

            <span :class="{ 'on': item.isWin }">{{ item.isWin ? "已中奖" : "未中奖" }}</span>
          </div>
        </div>
        <div>
          <span>{{ 'NO.' + item.sort }}</span>
          <span>{{ item.model }}</span>
          <span>{{ item.typeName }}</span>
        </div>
        <div>共&nbsp;<span>{{ item.count }}</span>&nbsp;注&nbsp;<span>{{ item.amount
            }}</span>&nbsp;元&nbsp;&nbsp;&nbsp;奖金<span>&nbsp;{{ item.bonus }}</span>&nbsp;元 </div>
        <div>
          <ul>
            <li v-for="(item2, index) in item.kanBanMapList" :key="index">
              <span>{{item2.name}}</span>
              <div class="numbers">
                <span v-for="(it, index) in item2.newHitNumbers" :key="index" class="number"
                  :class="{ 'on': it.hit, 'blue': item2.name == '蓝球' }">{{isUnlock ? it.number : '?'}}</span>
              </div>
            </li>
          </ul>
        </div>
        <div>
          {{ item.createTime }}
        </div>
      </div>

      <div v-if="!isUnlock" class="overlay">
        <span><img src="./img/bsuo.png" alt="" style="display: block; width: 20px; height: 20px;"></span>
        <span style="font-size: 14px;color: #3787FF;">解锁后翻车必退</span>
        <span style="font-size: 11px;color: #FF5337;">注释：方案总金额大于中奖金额为翻车，解锁费用秒退</span>
      </div>
    </div>

    <div style="height: 60px;"></div>

    <div class="button-container" style="position: fixed;left: 0;bottom: 0;padding: 12px 16px;">
      <a href="https://chatgpt5000.com/www/app/download.html" class="button1">去下载</a>
      <button :class="{ button2: isUnlock === false, button3: isUnlock === true }"
        @click="unlockClick">¥{{amount}}解锁方案&nbsp;<img v-if="!isUnlock" src="./img/suo.png" class="img"> </button>
      <div v-if="!isUnlock" class="bitui">翻车必退</div>
    </div>

    <transition name="modal">
            <div class="modal-mask" v-if="showPayTypeModal" @click="showPayTypeModal = false"></div>
        </transition>

        <transition name="slide-bottom">
            <div class="payment-selector" v-if="showPayTypeModal">
                <div class="close">
                    <div v-if="isWeChat" @click="openPhoneWbToPay" style="color: #3787FF;">请在手机浏览器中支付</div>
                    <div v-else></div>
                    <img src="./img/pay_close.png" @click="hide" style="height: 18px;width: 18px;">
                </div>
                <div class="close">
                    <span style="font-size: 16px;font-weight: 600;">解锁号码</span>
                    <span style="font-size: 1px;font-weight: 600;color: #FF5337;">{{amount}}元</span>
                </div>
                <div class="payType">
                    <div class="alipay" @click="alipayPay">
                        <div><img src="./img/alipay.png" style="height: 30px;width: 30px;">&nbsp;&nbsp;支付宝</div>
                        <img v-if="type===0" src="./img/selected.png" style="height: 20px;width: 20px;">
                        <img v-else src="./img/unselected.png" style="height: 20px;width: 20px;">
                    </div>
                    <div style=" height: 1px;width: 100%;background-color: #f0f0f0;"></div>
                    <div class="wechat" @click="wechatPat">
                        <div><img src="./img/wechat.png" style="height: 30px;width: 30px;">&nbsp;&nbsp;微信</div>
                        <img v-if="type===1" src="./img/selected.png" style="height: 20px;width: 20px;">
                        <img v-else src="./img/unselected.png" style="height: 20px;width: 20px;">
                    </div>
                </div>
                <button @click="toPay" class="close-button">确认支付</button>
            </div>
        </transition>
  </div>
  
  <div class="dialog" v-if="!isClose">
    <div>
      <span>翻车必退规则</span>
      <span>
        在蝌蚪之家，如果用户选择了付费分享，但当晚的开奖结果显示总投注金额大于中奖金额时，我们将在开奖结果公布后30分钟内启动“翻车必退”政策。用户支付的解锁费用将通过支付系统原路退还。这一措施不仅确保了用户在未赢得预期金额时可以收回其费用，同时也大大降低了参与的风险。
      </span>
    </div>
    <button @click="closeClick"><img v-if="!isUnlock" src="./img/close.png" style="width: 35px;height: 35px;"></button>
  </div>

  <div class="guide-page" v-if="isWeChat && showGuidePage">
    <img src="./img/guide_page.png" style="width: 100%;">
  </div>


</template>

<script>

import {shareNumber, createH5Alipay, createWxH5Orde} from "@/api/api";
import axios from 'axios'

export default {
  name: "NumbersPage",
  data() {
    return {
      lotteryList: [],  /// 号码数组
      remark: '',  /// 保存组
      userInfo: {},  /// 用户信息
      id:'',  /// 分享码id
      isUnlock: false,  /// 是否显示支付弹窗
      winAmount: 0,   /// 中奖金额
      winCount: 0,  /// 中奖次数
      amount: 0,  /// 解锁金额
      isClose: false,  ///  是否关闭翻车解释弹窗
      productId: '',  /// 产品id
      showPayTypeModal: false,   /// 显示支付类型弹窗
      showGuidePage: false,   /// 微信显示引导页
      type: 0,   /// 0支付宝 1微信
      userIP: '',  /// 手机ip
      lotteryCode:'',  /// 彩种类型
      uid: '',  /// 页面路由分享码id
      isWeChat: false,  /// 是否微信
      os: '未知操作系统',  /// 操作系统
    }
  },
  created() {
    this.detectBrowser();
    this.getUserIP();
    this.uid = this.$route.query.uid;
    // this.uid = '800179';
    shareNumber(this.uid).then(res => {
      // console.log(res);
      if (res.userInfo) {
        this.lotteryList = res.lotteryList;
        this.userInfo = res.userInfo;
        this.remark = res.remark;
        this.id = res.id;
        this.isUnlock = res.isUnlock;
        this.winAmount = res.winAmount;
        this.winCount = res.winCount;
        this.amount = res.amount;
        this.productId = res.productId;
        this.lotteryCode = res.lotteryCode;
        if (this.isUnlock) {
          this.isClose = true;
        }
      } else {
        // 兼容
        this.lotteryList = res;
      }
    });
  },
  computed: {
    
  },
  methods: {

    detectBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      this.isWeChat = /micromessenger/.test(ua);

      const userAgent = window.navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.os = 'iOS';
      } else if (userAgent.indexOf('Android') > -1) {
        this.os = 'Android';
      }
    },

    async getUserIP() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json'); 
        this.userIP = response.data.ip;
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    },

    checkClick() {
      return this.$router.push({ path: '/winrecord', query: { userId: this.userInfo.userId, lotteryCode: this.lotteryCode } });
    },

    unlockClick() {
      if (this.isWeChat) {
        this.showGuidePage = true;
        return;
      }
      const sessionId = localStorage.getItem('sessionId')
      if (sessionId === null) {
        return this.$router.push(`/login`);
      }
      if (!this.isUnlock) {
        this.showPayTypeModal = true;
      }
    },

    closeClick() {
      this.isClose = true;
    },

    hide() {
      this.showPayTypeModal = false;
    },
    alipayPay() {
      this.type = 0;
    },
    wechatPat() {
      this.type = 1;
    },
    toPay() {
      this.hide();
      if (this.type === 0) {
        this.createAlipayOrder();
      } else {
        this.createWeChatOrder();
      }
    },

    createAlipayOrder(){
      try {
        createH5Alipay(this.productId, this.userIP, this.id, this.os).then(res => {
          // console.log(res);
          if (res !== null) {
            if (this.os == 'iOS') {
              window.location.href = res.action;
              return;
            }
            window.open(res.action, '_blank');
          } else {
            console.error('Invalid response:', res);
          }
        });
      } catch (error) {
        console.error('Error initiating payment:', error);
      }
    },

    createWeChatOrder() {
      try {
        createWxH5Orde(this.productId, this.userIP, this.id, this.os).then(res => {
          // console.log(res);
          if (res !== null) {
            if (this.os == 'iOS') {
              window.location.href = res.h5Url+'&https%3A%2F%2Fbbs.ssqai.com%2Fkedouhouse%2F%23%2Fnumbers%3Fuid%3D'+this.uid;
              return;
            }
            window.open(res.h5Url+'&https%3A%2F%2Fbbs.ssqai.com%2Fkedouhouse%2F%23%2Fnumbers%3Fuid%3D'+this.uid, '_blank');
          } else {
            console.error('Invalid response:', res);
          }
        });
      } catch (error) {
        console.error('Error initiating payment:', error);
      }
    },
  },
  
}

</script>

<style scoped lang="scss">

.app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
}

.user {
  display: flex;
  padding: 12px 16px 16px 16px;

  .name{
    display: flex;
    justify-content: space-between;
    >span {
      font-size: 12px;
      color: #999999;
    }
  }

  >img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    border: 1px solid #999;
    background-color: #999;
  }

  >div {
    margin-left: 8px;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;

    >h4 {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      padding-bottom: 4px;
    }

    >span {
      font-size: 12px;
      color: #999999;
    }
  }
}

.win {
  display: flex;
  position: relative;
  /* 使子元素横向排列 */
  justify-content: space-between;
  /* 可选：子元素之间的间距 */
  align-items: center;
  /* 可选：垂直居中对齐 */
  // padding: 10px 12px 10px 10px;
  margin: 0 16px 12px 16px;
  border-radius: 3px;
  background-color: #fdf1ef;
  height: 70px;

  >img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 48px;
  }

  .left {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    /* 水平居中对齐 */
    padding-left: 60px;

    .count {
      display: flex;
      flex-direction: column;
      /* 设置为列方向 */
      justify-content: center;
      /* 垂直居中对齐 */
      align-items: center;
      /* 水平居中对齐 */

      >span:nth-child(1) {
        font-size: 14px;
        color: #333333;
      }

      >span:nth-child(2) {
        font-size: 14px;
        color: #FF5337;
      }
    }
  }

  .right{
    background-color: #FF5337;
    /* 背景颜色 */
    color: #FFF;
    /* 文字颜色 */
    font-size: 14px;
    border: none;
    /* 移除默认边框 */
    cursor: pointer;
    /* 改变鼠标指针形状 */
    text-align: center;
    /* 居中文本 */
    text-decoration: none;
    /* 移除文本下划线 */
    display: inline-block;
    /* 使其能够与其它元素一起使用 Flexbox */
    width: 80px;
    height: 30px;
    border-radius: 15px;
    margin-right: 15px;
  }

}

.remark {
  display: flex;
  padding: 10px 12px 10px 10px;
  margin: 0 16px 12px 16px;
  background-color: #F7F8FA;
  color: #999999;
  border-radius: 3px;
  font-size: 12px;

  >span:nth-child(1) {
    margin-top: 1px;
    margin-right: 5px;
  }
}

.items {
  padding: 0 16px 12px 16px;
  position: relative;

  .overlay {
    flex-direction: column;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    /* 水平居中对齐 */
    position: absolute;
    top: 85px;
    left: 18px;
    bottom: 14px;
    right: 18px;
    backdrop-filter: blur(10px); // 模糊图层
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.item {
  padding: 12px;
  border: 1px solid #F0F0F0;
  border-radius: 6px;

  >div:nth-child(1) {
    display: flex;
    justify-content: space-between;

    >div:nth-child(1) {
      color: #333;
      font-weight: bold;
    }

    >div:nth-child(2) {
      >span {
        background-color: #F8F8F8;
        color: #666;
        padding: 2px 8px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 1;
        margin-left: 4px;

        &.on {
          background-color: #FF5337;
          color: #FFF;
        }
      }
    }
  }

  >div:nth-child(2) {
    font-size: 12px;
    padding-top: 8px;

    >span:nth-child(1) {
      background-color: #FDF1ED;
      color: #FF5337;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px;
      line-height: 1;
      margin-right: 4px;
    }

    >span:nth-child(2) {
      background-color: #F0F5FE;
      color: #3787FF;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px;
      line-height: 1;
      margin-right: 4px;
    }

    >span:nth-child(3) {
      background-color: #F8F8F8;
      color: #666666;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px;
      line-height: 1;
      margin-right: 4px;
    }
  }

  >div:nth-child(3) {
    font-size: 12px;
    color: #999999;
    padding: 8px 0;

    span {
      color: #FF5337;
    }
  }

  >div:nth-child(4) {
    padding: 10px 8px;
    background-color: #F7F8FA;
    border-radius: 6px;

    ul>li {
      display: flex;
      padding-bottom: 8px;

      &:last-child {
        padding-bottom: 0;
      }

      span {
        // padding-top: 5px;
        color: #999999;
        margin-right: 8px;
      }
    }
  }

  >div:nth-child(5) {
    font-size: 12px;
    color: #999999;
    padding-top: 8px;
  }

}

.numbers {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  width: 80%;

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 5px;
    line-height: 1;
    color: rgba(255, 83, 55, 1) !important;
    word-wrap: break-word;

    &.blue {
      color: rgba(55, 135, 255, 1) !important;
    }

    &.on {
      color: #FFFFFF !important;
      background: rgba(255, 83, 55, 1);
    }

    &.on.blue {
      background: rgba(55, 135, 255, 1);
    }
  }
}

.button-container {
  display: flex;
  position: relative;
  /* 使用 Flexbox */
  justify-content: space-between;
  /* 在按钮之间平均分配剩余空间 */
  width: 100%;

  /* 宽度设为 100% 以占据整个容器或屏幕的宽度 */
  .button1 {
    flex: 1;
    /* 每个按钮占据相等的空间 */
    margin: 5px;
    /* 添加一些边距 */
    padding: 10px 20px;
    /* 内边距 */
    background-color: #3787FF;
    /* 背景颜色 */
    color: #3787FF;
    /* 文字颜色 */
    border: none;
    /* 移除默认边框 */
    cursor: pointer;
    /* 改变鼠标指针形状 */
    text-align: center;
    /* 居中文本 */
    text-decoration: none;
    /* 移除文本下划线 */
    display: inline-block;
    /* 使其能够与其它元素一起使用 Flexbox */
    font-size: 16px;
    /* 字体大小 */
    height: 44px;
    border-radius: 100px;
    background: #FFFFFF;
    border: 1px solid #3787FF;
  }

  .button2 {
    display: flex;
    flex: 3;
    /* 每个按钮占据相等的空间 */
    margin: 5px;
    /* 添加一些边距 */
    padding: 10px 20px;
    /* 内边距 */
    background-color: #3787FF;
    /* 背景颜色 */
    color: #FFF;
    /* 文字颜色 */
    border: none;
    /* 移除默认边框 */
    cursor: pointer;
    /* 改变鼠标指针形状 */
    text-align: center;
    /* 居中文本 */
    text-decoration: none;
    /* 移除文本下划线 */
    display: inline-block;
    /* 使其能够与其它元素一起使用 Flexbox */
    font-size: 16px;
    /* 字体大小 */
    height: 44px;
    border-radius: 100px;
    border: 1px solid #3787FF;
    .img{
      width: 16px; /* 图片宽度 */
      height: 16px; /* 图片高度 */
    }
  }

  .button3 {
    display: flex;
    flex: 3;
    /* 每个按钮占据相等的空间 */
    margin: 5px;
    /* 添加一些边距 */
    padding: 10px 20px;
    /* 内边距 */
    background-color: #f7f8fa;
    /* 背景颜色 */
    color: #cccccc;
    /* 文字颜色 */
    border: none;
    /* 移除默认边框 */
    cursor: pointer;
    /* 改变鼠标指针形状 */
    text-align: center;
    /* 居中文本 */
    text-decoration: none;
    /* 移除文本下划线 */
    display: inline-block;
    /* 使其能够与其它元素一起使用 Flexbox */
    font-size: 16px;
    /* 字体大小 */
    height: 44px;
    border-radius: 100px;
  }

  .bitui {
    position: absolute;
    right: 10px;
    height: 20px;
    width: 60px;
    padding-top: 2px;
    background-color: #FF7D00;
    text-align: center;
    font-size: 11px;
    color: #FFF;
    border-radius: 10px;
  }
}

.dialog {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  flex-direction: column;
  /* 设置为列方向 */
  justify-content: center;
  /* 垂直居中对齐 */
  align-items: center;
  /* 水平居中对齐 */
  // z-index: 1000;

  >div {
    width: 80%;
    background-color: #FFFFFF;
    opacity: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;

    >span:nth-child(1) {
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 10px;
    }

    >span:nth-child(2) {
      font-size: 14px;
      color: #666666;
    }
  }
  >button {
    border: none;
    /* 移除默认边框 */
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);;
  }
}

.guide-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}


.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.payment-selector {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payType {
    display: flex;
    margin-bottom: 10px;
    background-color: #F8F8F8;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px;

    .alipay {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;

        >div:nth-child(1) {
            display: flex;
            text-align: center;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    .wechat {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;
        >div:nth-child(1) {
            display: flex;
            text-align: center;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}

.close-button {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background: #3787FF;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 100px;
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
    transition: all 0.3s ease;
}

.slide-bottom-enter,
.slide-bottom-leave-to {
    transform: translateY(100%);
}

</style>