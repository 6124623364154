<template>
    <div class="app">
      <img src="./img/pure.png" alt="" style="display: block;width: 100%; padding-bottom: 60px;">
      <button style="position: fixed;left: 0;right: 0;bottom: 0;padding: 12px 16px;border: none; background-color: transparent;" data-agl-cvt="6">
        <a style="
            height: 44px;border-radius: 100px;background: #3787FF;
            display: flex;
            justify-content: center;
            align-items: center;font-size: 15px;color: #FFF;" @click="download">v{{androidVersion}} 去下载</a>
      </button>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "DownloadPage",
    data() {
      return {
        androidVersion: '',
        downLoadUrl: '',
      }
    },
    computed: {
  
    },
    methods: {
      download() {
        // 文件 URL 或数据
        const fileUrl = this.downLoadUrl;
        // 创建隐藏的可下载链接
        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';
        document.body.appendChild(link);
        // 触发点击
        link.click();
        // 清理
        document.body.removeChild(link);
      }
    },
    created() {
      this.androidVersion = this.$route.query.androidVersion;
      this.downLoadUrl = this.$route.query.downLoadUrl;
    }
  }

  </script>
  
  <style scoped lang="scss">
  .app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    /* 示例高度 */
    overflow-y: auto;
  }
  </style>